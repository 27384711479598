import { Container, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./NavMenu.scss";
import { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box/Box";
import List from "@mui/material/List/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemButton from "@mui/material/ListItemButton/ListItemButton";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import AppBar from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import IconButton from "@mui/material/IconButton/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button/Button";
import Drawer from "@mui/material/Drawer/Drawer";
import NavMenuAvatar from "./NavAvatar";
import {
  ApplicationRole,
  ReportTypes,
  UserNotificationResponse,
  UsersService,
} from "../../api";
import { useSelector } from "react-redux";
import { isEmpty } from "../../services/Helpers";
import { Collapse, Menu, MenuItem, Popover } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { usersToTrackTime } from "../../pages/Admin/LeadsManagement/shared/Constants";

const drawerWidth = 240;

type NavItem = {
  name: string;
  link?: string;
  subItems?: NavItem[];
};

const adminNavItems: NavItem[] = [
  {
    name: "Leads",
    subItems: [
      { name: "New leads", link: "/new-leads" },
      { name: "Active clients", link: "/active-clients" },
      { name: "Referral leads", link: "/referral-leads" },
      { name: "Attorney review", link: "/approve-leads" },
      { name: "Archive", link: "/archive" },
    ],
  },
  { name: "Referral Attorneys", link: "/attorneys" },
  { name: "Intake", link: "/lead/new" },
  { name: "Attorney review schedule", link: "/review-assignment" },
  //{ name: "Create new user", link: "/register" },
  { name: "Generate Registration Link", link: "/generateLink?setAny=true" },
  {
    name: "Leads Reports",
    subItems: [
      { name: "Staff", link: `/reports/leads/${ReportTypes.LEADS_STAFF}` },
      { name: "Attorney", link: `/reports/leads/${ReportTypes.LEADS_ATTORNEY}` },
      { name: "Intake overview", link: `/reports/leads/${ReportTypes.LEADS_DATE}` },
    ],
  },
];

const staffNavItems: NavItem[] = [
  { name: "New leads", link: "/new-leads" },
  { name: "Active clients", link: "/active-clients" },
  { name: "Intake", link: "/lead/new" },
];

const staffAttorneyNavItems: NavItem[] = [
  { name: "Attorney review", link: "/approve-leads" },
  { name: "Zoom", link: "/zoom" },
  {
    name: "Clio Reports",
    link: `/reports/clio/${ReportTypes.OVERVIEW}`
  },
  { name: "Extensions", link: "/extensions" },
];

const attorneyNavItems: NavItem[] = [
  { name: "Leads", link: "/attorney-leads" },
  //{ name: "Cases", link: "/attorney-cases" },
];

const userNavItems: NavItem[] = [
  { name: "Fill in info", link: "/attorney-info" },
];

const referalNavItems: NavItem[] = [
  { name: "Attorney review", link: "/approve-leads" },
  { name: "Referral leads", link: "/referral-leads" },
];

const clioStaffNavItems: NavItem[] = [
  { name: "Zoom", link: "/zoom" },
  {
    name: "Clio Reports",
    link: `/reports/clio/${ReportTypes.OVERVIEW}`
  },
  { name: "Extensions", link: "/extensions" },
];

const clioAdminNavItems: NavItem[] = [
  { name: "Zoom", link: "/zoom" },
  {
    name: "Clio Reports",
    subItems: [
      { name: "Overview", link: `/reports/clio/${ReportTypes.OVERVIEW}` },
      { name: "Extensions", link: `/reports/clio/${ReportTypes.EXTENSIONS}` },
      { name: "Stage changes", link: `/reports/clio/${ReportTypes.MATTER_STAGE_CHANGE}` },
      { name: "Assignee change", link: `/reports/clio/${ReportTypes.TASK_ASSIGNEE_CHANGE}` },
      { name: "Litigation Settlement overview", link: `/reports/clio/${ReportTypes.SETTLEMENT_LIT}` },
      { name: "Pre-lit Settlement overview", link: `/reports/clio/${ReportTypes.SETTLEMENT_PRELIT}` },
      { name: "KPI", link: `/reports/clio/${ReportTypes.KPI}` },
      { name: "Negotiation results", link: `/reports/clio/${ReportTypes.NEGOTIATION_RESULT}` },
      { name: "SOLES", link: `/reports/clio/${ReportTypes.SOLES}` },
    ],
  },
  { name: "Extensions", link: "/extensions" },
  { name: "Completed/Rejected Extensions", link: "/extensions/archived" },
  { name: "Assign Clio tasks", link: "/matterInteractions" },
  { name: "Edit tasks", link: "/taskInteractions" },
];

export default function NavMenu() {
  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openSubmenu, setOpenSubmenu] = useState({});
  const [notifications, setNotifications] =
    useState<UserNotificationResponse[]>();
  const [openMenus, setOpenMenus] = useState({});

  const zoomPhoneKey = "ZOOM_EXT_ID";
  const phoneId = localStorage.getItem(zoomPhoneKey);

  const handleMenuToggle = (index) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [index]: !prevOpenMenus[index],
    }));
  };

  const handleSubToggle = (index) => {
    setOpenSubmenu((prevAnchorEls) => ({
      [index]: !prevAnchorEls[index],
    }));
  };
  const location = useLocation();
  const user = useSelector((state: any) => state.user?.user);

  useEffect(() => {

    if (isEmpty(user)) {
      setNavItems([]);
      return;
    }

    UsersService.getApiUsersNotifications().then((userNotifications) => {
      setNotifications(userNotifications);
    });
    const rolesSet = new Set(user.roles);
    var navItems: NavItem[] = [];
console.log(rolesSet);
    if (rolesSet.has(ApplicationRole.ADMIN)) {
      navItems = [...navItems, ...adminNavItems];
    }
    if (rolesSet.has(ApplicationRole.ATTORNEY)) {
      navItems = [...navItems, ...attorneyNavItems];
    }
    if (rolesSet.has(ApplicationRole.STAFF)) {
      navItems = [...navItems, ...staffNavItems];
    }
    if (rolesSet.has(ApplicationRole.STAFF_ATTORNEY)) {
      navItems = [...navItems, ...staffAttorneyNavItems];
    }
    if (rolesSet.has(ApplicationRole.USER)) {
      navItems = [...navItems, ...userNavItems];
    }
    if (rolesSet.has(ApplicationRole.REFFERAL_STAFF)) {
      navItems = [...navItems, ...referalNavItems];
    }
    if (rolesSet.has(ApplicationRole.CLIO_STAFF)) {
      navItems = [...navItems, ...clioStaffNavItems];
    }
    if (rolesSet.has(ApplicationRole.CLIO_ADMIN)) {
      navItems = [...navItems, ...clioAdminNavItems];
    }

    if(usersToTrackTime.includes(user.id)){
      navItems = [...navItems, { name: "Time tracking", link: "/timekeeping" }];
    }
    setNavItems(navItems);
  }, [user]);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleNotificationsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setNotificationsAnchorEl(e.currentTarget);
    UsersService.postApiUsersNotifications().then();
  };
  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  const open = Boolean(notificationsAnchorEl);
  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item, index) => (
          <ListItem key={item.name} disablePadding className="flex-wrap">
            {item.link && (
              <ListItemButton
                component={Link}
                to={item.link}
                selected={item.link === location.pathname}
                onClick={handleDrawerToggle}
              >
                <ListItemText primary={item.name} />
              </ListItemButton>
            )}
            {item.subItems && (
              <>
                <ListItemButton onClick={() => handleMenuToggle(index)}>
                  <ListItemText primary={item.name} />
                </ListItemButton>
                <div className="row col-12">
                  <Collapse
                    in={!!openMenus[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List disablePadding className="ms-4">
                      {item.subItems.map((subItem) => (
                        <ListItem
                          key={subItem.name}
                          disablePadding
                          onClick={handleDrawerToggle}
                        >
                          <ListItemButton
                            component={Link}
                            to={subItem.link!}
                            selected={subItem.link === location.pathname}
                          >
                            <ListItemText primary={subItem.name} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </div>
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <header>
      <Container>
        <Row>
          <AppBar component="nav">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: "none" } }}
                className="flex-grow-1 justify-content-start"
              >
                <MenuIcon />
              </IconButton>
              {isEmpty(user) && (
                <>
                  <Button component={Link} to="/login">
                    Login
                  </Button>
                </>
              )}
              {(!isEmpty(user) || !!phoneId) && (
                <>
                  <List
                    sx={{
                      flexGrow: 1,
                      display: { xs: "none", sm: "flex" },
                      padding: 0,
                    }}
                  >
                    {navItems.map((item, index) => (
                      <ListItem
                        className="nav-list-item"
                        key={item.name}
                        disablePadding
                      >
                        {item.link && (
                          <ListItemButton
                            sx={{ textAlign: "center" }}
                            component={Link}
                            to={item.link}
                            selected={item.link === location.pathname}
                          >
                            <ListItemText primary={item.name} />
                          </ListItemButton>
                        )}
                        {item.subItems && (
                          <>
                            <ListItemButton
                              sx={{ textAlign: "center" }}
                              selected={item.link === location.pathname}
                              onClick={(
                                event: React.MouseEvent<HTMLElement>
                              ) => {
                                setAnchorEl(event.currentTarget);
                                handleSubToggle(index);
                              }}
                            >
                              <ListItemText primary={item.name} />
                            </ListItemButton>
                            <Menu
                              sx={{ mt: "45px" }}
                              id="menu-appbar"
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              keepMounted
                              transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                              open={!!openSubmenu[index]}
                              onClose={() => {
                                setAnchorEl(null);
                                handleSubToggle(index);
                              }}
                            >
                              {item.subItems.map((item) => (
                                <MenuItem
                                  key={item.name}
                                  sx={{ textAlign: "center" }}
                                  component={Link}
                                  to={item.link!}
                                  selected={item.link === location.pathname}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Menu>
                          </>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </>
              )}
              {!isEmpty(user) && (<>
                <IconButton
                  className="me-3 notifications-icon"
                  onClick={handleNotificationsClick}
                >
                  <NotificationsIcon />
                  {!!notifications &&
                    notifications.filter((n) => !n.isRead).length > 0 && (
                      <span className="new-messages-count">
                        {notifications?.filter((n) => !n.isRead).length}
                      </span>
                    )}
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={notificationsAnchorEl}
                  onClose={handleNotificationsClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className="notifications-holder"
                >
                  <div>
                    {!!notifications && notifications?.length > 0 ? (
                      notifications?.map((notification) => (
                        <div
                          key={notification.id}
                          className={
                            notification.isRead
                              ? "row m-0 p-3 notification"
                              : "row m-0 p-3 notification new"
                          }
                          onClick={() => {
                            if (!!notification.link) {
                              window.location.href = notification.link;
                            }
                          }}
                        >
                          <p>{notification.content}</p>
                        </div>
                      ))
                    ) : (
                      <div className="row m-0 p-3 notification">
                        <p>No notifications</p>
                      </div>
                    )}
                  </div>
                </Popover>

                <NavMenuAvatar></NavMenuAvatar>
              </>
              )}

            </Toolbar>
          </AppBar>
          <Box component="nav">
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </Box>
        </Row>
      </Container>
    </header>
  );
}
