import * as yup from "yup";
import {
  AttorneyApprovalStatus,
  AttorneyLeadStatus,
  AttorneyStatus,
  Category,
  ContactReason,
  CSAType,
  EmployeePaidType,
  Injuries,
  LeadAttorneysResponse,
  LeadViewModel,
  PaidWithheld,
  RadioOptions,
  ReasonForDeclinePI,
  ReasonForKeepPI,
  ReasonForNAEmp,
  ReasonForNAPI,
  ReasonForNgcWC,
  ReasonForReferPI,
  TaskExtentionStatus,
} from "../../../../api";
import { AdminLeadStatus } from "../../../../api/models/AdminLeadStatus";
import { HelpersService } from "../../../../services/Helpers";

export const RadioYesOption = HelpersService.getEnumKeyByEnumValue(
  RadioOptions,
  RadioOptions.YES
);

export const errorInstructions =
  "Please try again. If the error still persists, please contact us.";

export enum LeadType {
  Admin = "Admin",
  Attorney = "Attorney",
  Case = "Case",
  StaffAttorney = "StaffAttorney",
}

// Leads table
export enum Stage {
  new = "NEW",
  shared = "SHARED",
  winner = "WINNER",
  toSign = "TO SIGN",
  signed = "SIGNED",

  //attorney view stages
  accepted = "Accepted",
  rejected = "Rejected",
  intake = "Intake",
  won = "Opportunity won",
  lost = "Opportunity lost",
}

export enum StageColors {
  cyan = "cyan",
  purple = "purple",
  dirtyYellow = "dirtyYellow",
  dirtyBlue = "dirtyBlue",
  dirtyGreen = "dirtyGreen",
  darkGray = "darkGray",
  brown = "brown",
  primary = "primary",
}

export enum SummaryType {
  Full = "Full",
  Redacted = "Redacted",
}
export enum SummaryTypeText {
  Full = "Full summary",
  Redacted = "Redacted summary",
}

export enum LeadStatus {
  Completed = "Completed",
  Rejected = "Rejected",
}
export enum IntakeStatus {
  Allow = "Allow",
  Block = "Block",
  Initiate = "Initiate",
}

export const StageColorsMapping = {
  [AdminLeadStatus.NEW]: StageColors.cyan,
  [AdminLeadStatus.SHARED]: StageColors.purple,
  [AdminLeadStatus.WINNER_SELECTED]: StageColors.dirtyYellow,
  [AdminLeadStatus.SENT_FOR_SIGNING]: StageColors.dirtyBlue,
  [AdminLeadStatus.SIGNED]: StageColors.brown,
  [AdminLeadStatus.PAID]: StageColors.dirtyGreen,

  //new intake
  [AdminLeadStatus.DRAFT]: StageColors.darkGray,
  [AttorneyApprovalStatus.IN_PROCESS]: StageColors.cyan,
  [AttorneyApprovalStatus.MORE_INFO_PROVIDED]: StageColors.purple,
  [AttorneyApprovalStatus.MORE_INFO_REQUESTED]: StageColors.purple,

  //keep
  [AdminLeadStatus.CONTRACT_SENT]: StageColors.purple,
  [AdminLeadStatus.CONTRACT_SIGNED]: StageColors.dirtyYellow,
  [AdminLeadStatus.SUPPLEMENTAL_ASSIGNED]: StageColors.dirtyBlue,
  [AdminLeadStatus.DONE]: StageColors.dirtyGreen,

  //attorneys
  [AttorneyLeadStatus.ACCEPTED]: StageColors.purple,
  //[AttorneyLeadStatus.INTRODUCTION]: StageColors.dirtyYellow,
  [AttorneyLeadStatus.WON]: StageColors.dirtyYellow,
  [AttorneyLeadStatus.LOST]: StageColors.dirtyBlue,

  //archived
  [AdminLeadStatus.CLIENT_REFERED]: StageColors.purple,
  [AdminLeadStatus.DECLINED]: StageColors.brown,
  [AdminLeadStatus.NGC]: StageColors.dirtyBlue,
  [AdminLeadStatus.N_A]: StageColors.dirtyYellow,
  [AdminLeadStatus.CSADECLINED]: StageColors.primary,
  [AdminLeadStatus.CLIENT_RETAINED]: StageColors.dirtyGreen,

  //extensions
  [TaskExtentionStatus.REQUESTED]: StageColors.dirtyBlue,
  [TaskExtentionStatus.APPROVED]: StageColors.dirtyGreen,
  [TaskExtentionStatus.REJECTED]: StageColors.brown,
  [TaskExtentionStatus.AUTHORIZED]: StageColors.purple,
  [TaskExtentionStatus.UNAUTHORIZED]: StageColors.primary,
  [TaskExtentionStatus.TASK_REMOVED]: StageColors.dirtyBlue,
  [TaskExtentionStatus.TASK_COMPLETED]: StageColors.dirtyYellow,
};

export const CategoryMapping = {
  [Category.EMPLOYMENT]: "Employment",
  [Category.WORKERS_COMPENSATION]: "Workers compensation",
  [Category.PERSONAL_INJURY]: "Personal Injury",
  [Category.CLASS_ACTION]: "Class Action",
};

export const ContactReasonDisplayMapping = {
  [ContactReason.DISCRIMINATION]: "Discrimination",
  [ContactReason.HARASSMENT]: "Harassment",
  [ContactReason.RETALIATION]: "Retaliation",
  [ContactReason.BREACH_OF_CONTRACT]: "Breach of Contract",
  [ContactReason.CLASS_ACTION]:
    "Class Actions - Problem with wages/​getting breaks",
};

export const EmployeePaidDisplayMapping = {
  [EmployeePaidType.MULTIPLE]: "More than one rate of pay",
  [EmployeePaidType.SALARY]: "Salary",
  [EmployeePaidType.COMMISSION]: "On a commission basis",
  [EmployeePaidType.PIECE_RATE]: "On a piece-rate basis",
  [EmployeePaidType.BONUSES]: "Bonuses",
  [EmployeePaidType.TIPS]: "Tips",
  [EmployeePaidType.HOURLY]: "Hourly",
  [EmployeePaidType.OTHER]: "Other",
};

export const PaidTimeDisplayMapping = {
  [PaidWithheld.WEEKLY_OVERTIME]: "Weekly Overtime",
  [PaidWithheld.DAILY_OVERTIME]: "Daily Overtime",
  [PaidWithheld.DOUBLE_TIME]: "Double Time",
  [PaidWithheld.TRAVEL_TIME]: "Travel Time",
  [PaidWithheld.REPORTING_TIME]: "Reporting Time",
  [PaidWithheld.STANDBY_TIME]: "Standby Time",
  [PaidWithheld.PREP_TIME]: "Prep Time",
  [PaidWithheld.TRAINING_TIME]: "Training Time",
  [PaidWithheld.ON_CALL_TIME]: "On-Call Time",
  [PaidWithheld.BONUS]: "Bonus/Commissions",
  [PaidWithheld.UNUSED_VACATION]: "Unused Vacation",
  [PaidWithheld.SICK_LEAVE]: "Sick Leave",
  [PaidWithheld.GRATUITIES]:
    "Gratuities(e.g. does manager/​supervisor take any tips?)",
  [PaidWithheld.OTHER]: "Other",
};

export const InjuriesDisplayMapping = {
  [Injuries.BRAIN_INJURY_CONCUSSION]: "Brain Injury/​Concussion",
  [Injuries.BROKEN_BONES]: "Broken Bones",
  [Injuries.LOSS_OF_LIFE]: "Loss of life",
  [Injuries.LOST_LIMB]: "Lost Limb",
  [Injuries.SPINAL_CORD]: "Spinal Cord Injury or Paralysis",
  [Injuries.SOFT_TISSUE]: "Soft Tissue(Sprains, strains, bruises)",
  [Injuries.NECK_AND_BACK]: "Neck and back pain",
  [Injuries.TOOTH]: "Tooth damage",
  [Injuries.OTHER]: "Other",
};

export const CSADisplayText = {
  [CSAType.PLO]: "PLO",
  [CSAType.STANDARD]: "Standard",
  [CSAType.FORTY_FIVE_TO_FIFTY]: "45-50",
  [CSAType.FORTY_TO_FORTY_FIVE_FIFTY]: "40-45-50",
  [CSAType.CLASS_ACTION]: "Class Action",
  [CSAType.PAGA]: "PAGA",
};

export const ReasonForKeepPIDisplayText = {
  [ReasonForKeepPI.AUTO]: "Auto",
  [ReasonForKeepPI.PREMISES_LIABILITY]: "Premises Liability",
  [ReasonForKeepPI.MED_MAL]: "Med-Mal",
};
export const ReasonForDeclinePIDisplayText = {
  [ReasonForDeclinePI.AUTO]: "Auto",
  [ReasonForDeclinePI.PREMISES_LIABILITY]: "Premises Liability",
  [ReasonForDeclinePI.MED_MAL]: "Med-Mal",
  [ReasonForDeclinePI.OTHER]: "Other",
};
export const ReasonForReferPIDisplayText = {
  [ReasonForReferPI.EASY_AUTO]: "Easy Auto",
  [ReasonForReferPI.NBCAUTO]: "Issue Auto",
  [ReasonForReferPI.EASY_PREMISES_LIABILITY]: "Easy Premises Liability",
  [ReasonForReferPI.CIVIL_RIGHTS]: "Civil Rights",
};
export const ReasonForDeclineWCDisplayText = {
  [ReasonForNgcWC.MENTAL]: "Mental",
  [ReasonForNgcWC.SOL]: "SOL",
  [ReasonForNgcWC.GLUCK_DECLINED]: "Gluck Declined",
  [ReasonForNgcWC.RATTO_DECLINED]: "Ratto Declined",
  [ReasonForNgcWC.LEAD_DECLINED]: "Lead Declined/Not Interested",
  [ReasonForNgcWC.OUT_OF_STATE]: "Out of State",
  [ReasonForNgcWC.NOT_WC]: "Not WC Case",
  [ReasonForNgcWC.NO_PAYSTUBS]: "Does Not Receive Paystubs",
  [ReasonForNgcWC.DID_NOT_REPORT]: "Did not report/Did not seek medical attention",
  [ReasonForNgcWC.ALREADY_HAS_SETTLEMENT]: "Already received a settlement",
  [ReasonForNgcWC.ALREADY_REPRESENTED]: "Already Represented and not looking for SOA",
  [ReasonForNgcWC.NO_WCINSURANCE]: "Employer does not have WC insurance",
  [ReasonForNgcWC.REPRIMANDED]: "Reprimanded before considering filing for WC",
  [ReasonForNgcWC.CREDIBILITY]: "Credibility",
  [ReasonForNgcWC.NO_INJURIES]: "No Injuries",
  [ReasonForNgcWC.GOVERNMENT_ENTITY]: "Government Entity",
};

export interface AttorneyLeadData {
  id: string;
  stage: Stage;
  category: Category;
  employer: string;
  lastUpdate: string;
}

export interface MyToken {
  name: string;
  exp: number;
  // whatever else is in the JWT.
}

// Lead details
export interface Milestone {
  name: string;
  done: boolean;
}

export interface AttorneyLead {
  id: string;
  leadName: string;
}
export interface LeadsBlock {
  id: string;
  status: Stage;
  leads: AttorneyLead[];
}

export interface LeadTableSummary {
  lead: LeadViewModel;
  summaryType: SummaryType;
}

export interface AttorneysStatusMapped {
  status: AttorneyStatus;
  attorneys: LeadAttorneysResponse[];
}

export const ReasonForNAEmpDisplayMapping = {
  [ReasonForNAEmp.EVIDENCE_NOT_STRONG]:
    "Evidence not Strong Enough / Signed Severance / Union",
  [ReasonForNAEmp.NOT_EXAUSTED_UNION]: "Have Not Exhausted Union Remedy",
  [ReasonForNAEmp.NOT_ILLEGAL]: "Not Illegal",
  [ReasonForNAEmp.CREDIBILITY]: "Credibility",
  [ReasonForNAEmp.INSUFFICIENT_FUNDS]: "Entity Too Small / Insufficient Funds",
  [ReasonForNAEmp.REFER_TO_LABOR]: "Claims Too Small / Refer to Labor Board",
  [ReasonForNAEmp.GIG_OR_GOVERNMENT]: "Gig or Government",
  [ReasonForNAEmp.SOL_EXPIRED]: "SOL Expired",
  [ReasonForNAEmp.REPRESENTED]: "Already Represented",
  [ReasonForNAEmp.SIGNED_SEVERANCE]: "Signed Severance",
  [ReasonForNAEmp.OUT_OF_STATE]: "Out of State",
  [ReasonForNAEmp.NOT_PLAINTIFF_CLAIM]: "Not a Plaintiff Employment Claim",
  [ReasonForNAEmp.REFER_WC]: "Refer to WC",
  [ReasonForNAEmp.NON_RESPONSIVE]: "Non-Responsive",
  [ReasonForNAEmp.OTHER] : "Other"
};

export const ReasonForPIEmpDisplayMapping = {
  [ReasonForNAPI.SUSPECTED_COMPETITOR]: "Suspected Competitor",
  [ReasonForNAPI.NO_INJURIES]: "No Injuries",
  [ReasonForNAPI.NOT_PI]: "Not a PI case",
  [ReasonForNAPI.DISCONNECT]: "Disconnected/Wrong number",
  [ReasonForNAPI.NO_INSURANCE]: "No Insurance/License",
  [ReasonForNAPI.CREDIBILITY]: "Credibility",
  [ReasonForNAPI.SOL_EXPIRED]: "SOL Expired",
  [ReasonForNAPI.NON_RESPONSIVE]: "Non-Responsive",
  [ReasonForNAPI.EVIDENCE_NOT_STRONG]: "Evidence Not Strong Enough",
  [ReasonForNAPI.RECEIVED_SETTLEMENT]: "Received Settlement",
  [ReasonForNAPI.ALREADY_REPRESENTED]:
    "Already Represented/Does not want to sub out",
  [ReasonForNAPI.OUT_OF_STATE]: "Out Of State",
  [ReasonForNAPI.CHANGED_MIND]: "Lead Changed Their Mind",
  [ReasonForNAPI.OTHER] : "Other"
};

export interface FileModel {
  file?: File;
  url?: string;
  key: string;
}

export const clientDataSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  phone: yup
    .string()
    .required(),
    // .test("is-valid-phone", "Invalid phone", function (value) {
    //   return matchIsValidTel(value);
    // }),
  email: yup.string().email().required(),
  birthDate: yup.mixed().required(),
});

export const maxFileSize = 104857600;

export const usersToTrackTime = [1,40,42,62,66,69,72];
