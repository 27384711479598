import AttorneysTable from "../../pages/Admin/Attorneys/AttorneysTable/AttorneysTable";
import CasesTable from "../../pages/Attorney/Cases/CasesTable";
import ProfileSettings from "../../pages/Profile/ProfileSettings";
import LeadDetailsView from "../../pages/Admin/LeadsManagement/LeadDetails/LeadDetails";
import AddLead from "../../pages/Admin/LeadsManagement/AddNewLead";
import Login from "../auth/Login";
import Register from "../auth/Register";
import SendLeads from "../../pages/Admin/LeadsManagement/SendLeads/SendLeads";
import ReviewAssignmentCalendar from "../../pages/Admin/Attorneys/ReviewAssignmentCalendar";
import SupplementalForm from "../../pages/Admin/LeadsManagement/LeadDetails/SupplementalForm";
import LeadsTable from "../../pages/Admin/LeadsManagement/LeadsTables/LeadsTable";
import { LeadTableType } from "../../api/models/LeadTableType";
import { ApplicationRole } from "../../api";
import AttorneysDetailsView from "../../pages/Admin/Attorneys/AttorneysDetails/AttorneysDetails";
import AttorneyInfo from "../../pages/Admin/Attorneys/Register/AttorneyInfo";
import GenerateLink from "../auth/GenerateLink";
import PasswordReset from "../auth/PasswordReset";
import ZoomPhone from "../../pages/Integrations/ZoomPhone";
import PaymentDone from "../../pages/Integrations/Stripe/PaymentDone";
import StripeCheckout from "../../pages/Integrations/Stripe/StripeCheckout";
import Reports from "../../pages/Reports/Reports";
import { ReportSource } from "../../pages/Reports/ReportsOptions";
import ExtensionsTable from "../../pages/Extensions/ExtensionsTable";
import ClassActionForm from "../../pages/Admin/LeadsManagement/LeadDetails/ClassActionForm";
import Timekeeping from "../../pages/Reports/Timekeeping/Timekeeping";
import ClioMatterInteraction from "../../pages/Integrations/ClioMatterInteraction";
import ClioTaskDataUpdate from "../../pages/Integrations/ClioTaskDataUpdate";

const AppRoutes = [
  //Leads tables
  {
    path: "/new-leads",
    element: <LeadsTable tableType={LeadTableType.INTAKE} />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF],
  },
  {
    path: "/active-clients",
    element: <LeadsTable tableType={LeadTableType.ACTIVE_CLIENT} />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF],
  },
  {
    path: "/archive",
    element: <LeadsTable tableType={LeadTableType.ARCHIVE} />,
    roles: [ApplicationRole.ADMIN],
  },
  //lead intake
  {
    path: "/lead/new",
    element: <AddLead />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF],
  },
  {
    path: "/lead/:id",
    element: <LeadDetailsView />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF, ApplicationRole.STAFF_ATTORNEY, ApplicationRole.ATTORNEY, ApplicationRole.REFFERAL_STAFF],
  },{
    path: "/lead/:id/review",
    element: <LeadDetailsView />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF, ApplicationRole.STAFF_ATTORNEY, ApplicationRole.ATTORNEY, ApplicationRole.REFFERAL_STAFF],
  },
  {
    path: "/lead/:id/supplemental",
    element: <SupplementalForm />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF],
  },
  {
    path: "/lead/:id/class",
    element: <ClassActionForm isEdit={true}/>,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF],
  },
  {
    path: "/lead/:id/class/review",
    element: <ClassActionForm isEdit={false}/>,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF, ApplicationRole.STAFF_ATTORNEY],
  },
  //lead approval
  {
    path: "/approve-leads",
    element: <LeadsTable tableType={LeadTableType.REVIEW} />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF_ATTORNEY, ApplicationRole.REFFERAL_STAFF],
  },
  {
    path: "/review-assignment",
    element: <ReviewAssignmentCalendar />,
    roles: [ApplicationRole.ADMIN],
  },
  //lead refer
  {
    path: "/referral-leads",
    element: <LeadsTable tableType={LeadTableType.REFERRAL} />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.REFFERAL_STAFF],
  },
  {
    path: "/attorneys",
    element: <AttorneysTable />,
    roles: [ApplicationRole.ADMIN],
  },
  {
    path: "/attorney/new",
    element: <AttorneysDetailsView isNew={true} />,
    roles: [],
  },
  {
    path: "/attorney/:id",
    element: <AttorneysDetailsView />,
    roles: [],
  },
  {
    path: "/lead/send/:ids",
    element: <SendLeads />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.REFFERAL_STAFF],
  },
  //attorney
  {
    path: "/attorney-leads",
    element: <LeadsTable tableType={LeadTableType.SHARED_ATTORNEY} />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.ATTORNEY],
  },
  {
    path: "/attorney-cases",
    element: <CasesTable />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.ATTORNEY],
  },
  {
    path: "/settings",
    element: <ProfileSettings />,
    roles: [],
  },
  {
    path: "/attorney-info",
    element: <AttorneyInfo />,
    roles: [ApplicationRole.USER],
  },{
    path: "/checkout/:id",
    element: <StripeCheckout />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.ATTORNEY],
  },
  {
    path: "/paymentDone",
    element: <PaymentDone />,
    roles: [ApplicationRole.ADMIN, ApplicationRole.ATTORNEY],
  },
  //auth
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/resetPassword",
    element: <PasswordReset />,
  },
  {
    path: "/generateLink",
    element: <GenerateLink />,
    roles: [ApplicationRole.ADMIN],
  },
  {
    path: "/linkRegister",
    element: <Register isLink />,
    roles: [ApplicationRole.CLIO_STAFF, ApplicationRole.ADMIN],
  },
  {
    path: "/linkRegisterUser",
    element: <Register isLink isStaff />,
  },

  //integrations
  {
    path: "/zoom",
    element: <ZoomPhone />,
    roles: [ApplicationRole.CLIO_STAFF, ApplicationRole.CLIO_ADMIN, ApplicationRole.ADMIN, ApplicationRole.STAFF_ATTORNEY],
  },
  {
    path: "/reports/clio/:reportType",
    element: <Reports source={ReportSource.Clio}/>,
    roles: [ApplicationRole.CLIO_STAFF, ApplicationRole.CLIO_ADMIN, ApplicationRole.ADMIN, ApplicationRole.STAFF_ATTORNEY],
  },
  {
    path: "/reports/leads/:reportType",
    element: <Reports source={ReportSource.Leads}/>,
    roles: [ApplicationRole.ADMIN],
  },
  {
    path: "/extensions",
    element: <ExtensionsTable/>,
    roles: [ApplicationRole.ADMIN, ApplicationRole.STAFF_ATTORNEY, ApplicationRole.CLIO_ADMIN, ApplicationRole.CLIO_STAFF],
  },
  {
    path: "/extensions/archived",
    element: <ExtensionsTable archived={true}/>,
    roles: [ApplicationRole.ADMIN, ApplicationRole.CLIO_ADMIN],
  },
  {
    path: "/timekeeping",
    element: <Timekeeping/>,
  },
  {path: "/matterInteractions",
    element: <ClioMatterInteraction/>,
  },
  {path: "/taskInteractions",
    element: <ClioTaskDataUpdate/>,
  }
];

export default AppRoutes;
