/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum ActivityCategories {
    SEND_COPY_PFDRAFT_FACT_SHEET = 'SendCopyPFDraftFactSheet',
    SCHEDULE_CLIENT_CALL_SEND_DLDRAFT = 'ScheduleClientCallSendDLDraft',
    SEND_EMAIL_NEED_WRITTEN_APPROVAL = 'SendEmailNeedWrittenApproval',
    RECEIVE_EMAIL_WRITTEN_APPROVAL = 'ReceiveEmailWrittenApproval',
    SEND_DEMAND_LETTER = 'SendDemandLetter',
    WEEKLY_FUOCON_DL = 'WeeklyFUOCOnDL',
    WEEKLY_FUCOUNTER_OFFER = 'WeeklyFUCounterOffer',
    GET_TERMINATION_APPROVAL = 'GetTerminationApproval',
    DRAFT_TERMINATION_SCHEDULE_CALL_CLIENT = 'DraftTerminationScheduleCallClient',
    GET_LITIGATION_APPROVAL = 'GetLitigationApproval',
    CALL_CLIENT_LITIGATE = 'CallClientLitigate',
    SEND_DRAFT_DOCUMENT_TO_CLIENT = 'SendDraftDocumentToClient',
    CONFIRM_COMPLAINT_SOC = 'ConfirmComplaintSOC',
    OBTAIN_INITIAL_DRAFT_SA = 'ObtainInitialDraftSA',
    SEND_FINAL_SARETRIEVE_ACCOUNTING_BREAKDOWN = 'SendFinalSARetrieveAccountingBreakdown',
    EMAIL_SAREVIEW_CALL = 'EmailSAReviewCall',
    CALL_CLIENT_SA = 'CallClientSA',
    SEND_SATO_OCADVISE_DEADLINE = 'SendSAToOCAdviseDeadline',
    GET_DOCUMENTS_ATTEMPT_MITIGATION = 'GetDocumentsAttemptMitigation',
    COORDINATE_CALL_CLIENT_AND_RAA = 'CoordinateCallClientAndRAA',
    FUCLIENT_ON_CPDS = 'FUClientOnCPDS',
    CALL_CLIENT_CASE_UPDATE = 'CallClientCaseUpdate',
    CALL_CLIENT_NEGOTIATIONS = 'CallClientNegotiations',
    PRE_LIT_STAFF_MEETING = 'PreLitStaffMeeting',
    STAFF_LIT_MEETING = 'StaffLitMeeting',
    WEEKLY_TEAM_MEETING = 'WeeklyTeamMeeting',
    MONTHLY_ALL_HANDS = 'MonthlyAllHands',
    WEEKLY_LIT_STAFF_MEETING = 'WeeklyLitStaffMeeting',
    CHECK_WITH_MANAGER = 'CheckWithManager',
    BUSINESS_DEVELOPMENT = 'BusinessDevelopment',
    INTERNAL_TRAINING = 'InternalTraining',
    ADMIN_NO_CASE = 'AdminNoCase',
    MCLE = 'MCLE',
    RESEARCH_NOT_CASE = 'ResearchNotCase',
    INTAKE_REVIEW = 'IntakeReview',
    REASSIGNMENT_CASES = 'ReassignmentCases',
    AUDITING_CASES = 'AuditingCases',
}
