/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum TaskTypes {
    DETERMINE_COA = 'DetermineCOA',
    DRAFT_DEMAND_LETTER = 'DraftDemandLetter',
    ONBOARDING = 'Onboarding',
    ASSIGN_STAFF = 'AssignStaff',
    SCHEDULE_CLIENT_CALL = 'ScheduleClientCall',
    REVIEW_CLAIMS = 'ReviewClaims',
    OBTAIN_WRITTEN_CLIENT_APPROVAL = 'ObtainWrittenClientApproval',
    SEND_DEMAND_LETTER = 'SendDemandLetter',
    WEEKLY_DEMAND_FOLLOW_UP = 'WeeklyDemandFollowUp',
    REVIEW_DEMAND_LETTER = 'ReviewDemandLetter',
    APPROVE_CASE_INITIATION = 'ApproveCaseInitiation',
    APPROVE_TERMINATION = 'ApproveTermination',
    CLIENT_CONSENT_LITIGATE = 'ClientConsentLitigate',
    CLOSE_MATTER_SETTLEMENT = 'CloseMatterSettlement',
    COMPLETE_SAREVIEW_WITH_CLIENT = 'CompleteSAReviewWithCLient',
    DECIDE_DRAFT_TYPE = 'DecideDraftType',
    ATORNEY_DETERMINATION = 'AtorneyDetermination',
    DRAFT_CASE_INITIATION = 'DraftCaseInitiation',
    DRAFT_SOC = 'DraftSOC',
    DRAFT_TERMINATION_LETTER = 'DraftTerminationLetter',
    EMAIL_SAAND_BREAKDOWN = 'EmailSAAndBreakdown',
    EXECUTE_STATEMENT = 'ExecuteStatement',
    FILE_AND_SERVE_SOC = 'FileAndServeSOC',
    FINALIZE_SOC = 'FinalizeSOC',
    FINALIZE_SAWITH_OC = 'FinalizeSAWithOC',
    OBTAIN_COUNSEL_INFORMATION = 'ObtainCounselInformation',
    GET_DOCS_MEDIATION_ATTEMPT = 'GetDocsMediationAttempt',
    OBTAIN_MEDICAL_INFORMATION = 'ObtainMedicalInformation',
    LIT_APPROVAL = 'LitApproval',
    ISSUE_TERMINATION_LETTER = 'IssueTerminationLetter',
    ISSUE_WARNING_LETTER = 'IssueWarningLetter',
    OBTAIN_INITIAL_SA = 'ObtainInitialSA',
    CLIENT_WRITTEN_APPROVAL_LITIGATION = 'ClientWrittenApprovalLitigation',
    RATE_CASE = 'RateCase',
    RECEIVE_CPD = 'ReceiveCPD',
    REVIEW_DOCS_PROVIDED_BY_CBI = 'ReviewDocsProvidedByCBI',
    REVIEW_TERMINATION = 'ReviewTermination',
    REVIEW_SOC = 'ReviewSOC',
    SEND_SOCDRAFT_TO_CLIENT = 'SendSOCDraftToClient',
    SEND_SARETRIVE_BREAKDOWN = 'SendSARetriveBreakdown',
    SEND_SATO_OC = 'SendSAToOC',
    SEND_CSA = 'SendCSA',
    SIGN_CSA = 'SignCSA',
    VERIFY_PAYMENT_RECEIVED = 'VerifyPaymentReceived',
    WEEKLY_FUIN_LITIGATION = 'WeeklyFUInLitigation',
    FACT_SHEET = 'FactSheet',
    REACH_CLIENT_WITNESSES = 'ReachClientWitnesses',
    DRAFT_WARNING_LETTER = 'DraftWarningLetter',
    OBTAIN_WCINFORMATION = 'ObtainWCInformation',
    COORDINATE_CALL_CLIENT_RAA = 'CoordinateCallClientRAA',
    ADDRESS_ATTY_QUESTIONS = 'AddressAttyQuestions',
    FUON_CPDS = 'FUonCPDS',
    CALL_CLIENT_ABOUT_CASE = 'CallClientAboutCase',
    INCORPORATE_EDITS_DL = 'IncorporateEditsDL',
    REVIEW_WARNING_LETTER = 'ReviewWarningLetter',
    INCORPORATE_EDITS_WARNING_LETTER = 'IncorporateEditsWarningLetter',
    DRAFT_RESPONSE_TO_OC = 'DraftResponseToOC',
    REVIEW_DRAFT_RESPONSE_TO_OC = 'ReviewDraftResponseToOC',
    DRAFT_TOLLING_AGREEMENT = 'DraftTollingAgreement',
    REVIEW_TOLLING_AGREEMENT = 'ReviewTollingAgreement',
    CONNECT_CLIENT_DURING_NEGOTIATIONS = 'ConnectClientDuringNegotiations',
    REVIEW_FSCHRON = 'ReviewFSChron',
    REVIEW_WITNESS_STATEMENT = 'ReviewWitnessStatement',
    CONVERT_TO_WITNESS_DECLARATION = 'ConvertToWitnessDeclaration',
    REVIEW_WITNESS_DECLARATION = 'ReviewWitnessDeclaration',
    REVIEW_MEDICAL_RECORDS_REQUEST = 'ReviewMedicalRecordsRequest',
    FINALIZE_MEDICAL_RECORDS_REQUEST = 'FinalizeMedicalRecordsRequest',
    ISSUE_MEDICAL_RECORDS_REQUEST = 'IssueMedicalRecordsRequest',
    REVIEW_CASE_FILE_REQUEST = 'ReviewCaseFileRequest',
    FINALIZE_CASE_FILE_REQUEST = 'FinalizeCaseFileRequest',
    ISSUE_CASE_FILE_REQUEST = 'IssueCaseFileRequest',
    REVIEW_WCFILE_REQUEST = 'ReviewWCFileRequest',
    FINALIZE_WCFILE_REQUEST = 'FinalizeWCFileRequest',
    ISSUE_WCFILE_REQUEST = 'IssueWCFileRequest',
    INCORPORATE_EDITS_SOC = 'IncorporateEditsSOC',
    FINALIZE_WITNESS_DECLARATION = 'FinalizeWitnessDeclaration',
    ADDITIONAL_QUESTIONS_REQUESTED = 'AdditionalQuestionsRequested',
    FUWARNING_LETTER_STATUS = 'FUWarningLetterStatus',
    DEMAND_FOLLOW_PENDING = 'DemandFollowPending',
    REVIEW_ARBITRATION_AGREEMENT = 'ReviewArbitrationAgreement',
    CLOSE_MATTER_TERM = 'CloseMatterTerm',
    CONFIRM_TERMINATION_APPROVAL = 'ConfirmTerminationApproval',
    CONFIRM_LIT_APPROVAL = 'ConfirmLitApproval',
    DRAFT_COMPLAINT_FOR_NEGO = 'DraftComplaintForNego',
    REVIEW_COMPLAINT_FOR_NEGO = 'ReviewComplaintForNego',
    UPDATE_FSCHRON = 'UpdateFSCHRON',
    COORDINATE_CALL_RAAOC = 'CoordinateCallRAAOC',
    LOOK_FOR_AAFILES_FOR_NEGO = 'LookForAAFilesForNego',
    DRAFT_MEDIATION_BRIEF = 'DraftMediationBrief',
    REVIEW_MEDIATION_BRIEF = 'ReviewMediationBrief',
    VERIFY_IF_DEFENDANT_PUBLIC = 'VerifyIfDefendantPublic',
    INTERNAL_FU = 'InternalFU',
    LOOK_FOR_ARBITRATION_AGREEMENT = 'LookForArbitrationAgreement',
    REVIEW_EDITS_SOC = 'ReviewEditsSOC',
    REVIEW_EDITS_DL = 'ReviewEditsDL',
    INCORPORATE_EDITS_SOC_SMAREQUEST = 'IncorporateEditsSOC_SMARequest',
    REVIEW_EDITS_SOC_SMAREQUEST = 'ReviewEditsSOC_SMARequest',
    CHECK_DLSTATUS = 'CheckDLstatus',
    CHECK_WARNING_LETTER_STATUS = 'CheckWarningLetterStatus',
    TRANSLATE_DOCUMENT = 'TranslateDocument',
    UPDATE_DC = 'UpdateDC',
    CHECK_SOCSTATUS = 'CheckSOCStatus',
    ADDITIONAL_QUESTIONS_FOR_CLIENT = 'AdditionalQuestionsForClient',
    REVIEW_CASE_FILES = 'ReviewCaseFiles',
    REVIEW_OCRESPONSE_TO_DL = 'ReviewOCResponseToDL',
    DRAFT_NOC = 'DraftNOC',
    REVIEW_NOC = 'ReviewNOC',
    DRAFT_CEAS_DESIST = 'DraftCeasDesist',
    REVIEW_CEAS_DESIST = 'ReviewCeasDesist',
    FINALIZE_ISSUE_CEAS_DESIST = 'FinalizeIssueCeasDesist',
    REQUEST_INITIAL_SETTLEMENT_FEE_BREAKDOWN = 'RequestInitialSettlementFeeBreakdown',
    SEND_SAAND_FEE_BREAKDOWN_TO_SMA = 'SendSAAndFeeBreakdownToSMA',
    DCDEADLINE_DEFENDANT_PAYMENT = 'DCDeadlineDefendantPayment',
    DISTRIBUTE_SETTLEMENT_PAYMENT = 'DistributeSettlementPayment',
}
