import FormControl from "@mui/material/FormControl/FormControl";
import Rating from "@mui/material/Rating/Rating";
import TextField from "@mui/material/TextField/TextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker/DatePicker";
import dayjs from "dayjs";
import * as yup from "yup";
import { useFormik, getIn } from "formik";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  AdminLeadStatus,
  Category,
  EmployedTimeType,
  EmployeePunishment,
  LeadsService,
  LeadViewModel,
  RadioOptions,
  SupplementalViewModel,
} from "../../../../api";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { HelpersService } from "../../../../services/Helpers";
import InputLabel from "@mui/material/InputLabel/InputLabel";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import RadioControl from "../../../../components/basic/RadioControl";
import FormHelperText from "@mui/material/FormHelperText/FormHelperText";
import { RadioYesOption } from "../shared/Constants";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import WitnessFormTable from "./WitnessFormTable";
import Button from "@mui/material/Button/Button";
import { useDispatch } from "react-redux";
import { startSpinner, stopSpinner } from "../../../../redux/actions/spinnerActions";

const MySwal = withReactContent(Swal);

const emptyData: SupplementalViewModel = {
  employedTimeType: undefined,
  hoursWorkedPerWeek: null,
  daysWorkedPerWeek: "",
  workSchedule: "",
  uninterruptedBreaks: undefined,
  breaksTakenWhen: "",
  breaksHowLong: "",
  havePolicyWhenToTakeBreaks: "",
  lastDayWorked: "",
  receivedLastPaycheck: undefined,
  lastPaycheckWhen: null,
  lastPaycheckIncludesAllWages: undefined,
  lastPaycheckIncludesVacations: undefined,
  believeEmployerStillOwesMoney: undefined,
  employerOwesHowMuch: null,
  employerOwesWagesType: "",
  employerOwesHowCalculatedAmount: "",
  haveCopyOfLastThreePayStubs: undefined,
  whyNoOrEffortsToGetStubs: "",
  whatPunishmentsOcurred: [],
  punishmentReasonGiven: "",
  punishmentReasonWhyImproper: "",
  punishmentSupportEvidence: "",
  hasWitnesses: undefined,
  witnesses: [],
  howLongSinceTermination: "",
  unpaidLeavesDates: "",
  unpaidLeavesLength: "",
  documentedEmotionalDistress: undefined,
  emotionalDistressDetails: "",
  filedBankruptcy: undefined,
  filedBankruptcyWhen: null,
  filedBankruptcyDischarged: "",
  providedEmployeeHandbook: undefined,
  haveWrittenContract: undefined,
  receivedEvaluationsOfWork: "",
  notifiedOfNegativeFeedbackInWriting: undefined,
  respondedToFeedbackInWriting: undefined,
  alreadyFiledLawsuit: undefined,
  filedLawsuitWhen: null,
  nameAndPhoneOfRepresentingAttorney: "",
  whyLookingForNewAttorney: "",
  anythingElseWeShouldKnow: "",
  supplementalNotes: "",
};
export default function SupplementalForm() {
  const [details, setDetails] = useState<LeadViewModel>({} as LeadViewModel);
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      return;
    }
    LeadsService.getApiLeadsSupplementalDetails(+id)
      .then((lead) => {
        if (!lead.supplementalDetails) {
          lead.supplementalDetails = emptyData;
          if (lead.category === Category.EMPLOYMENT) {
            lead.supplementalDetails.lastDayWorked =
              lead.employmentDetails?.terminationDate;
          }
        }
        var leadData = HelpersService.parseLeadSupplementalResponse(lead);
        setDetails(leadData);
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: <p className="title">Error retrieving lead data</p>,
        });
      });
  }, []);

  const onSubmit = (form) => {
    dispatch(startSpinner());
    setDetails(form);
    var updatedLead = form as LeadViewModel;
    updatedLead.supplementalDetails!.employedTimeType =
      EmployedTimeType[form.supplementalDetails!.employedTimeType];
    updatedLead.supplementalDetails!.isDraft = false;
    LeadsService.putApiLeadsUpdateSupplemental(+id!, updatedLead)
      .then(() =>
        MySwal.fire({
          icon: "success",
          title: (
            <p className="title">Supplemental form submitted successfully.</p>
          ),
        }).then(() => onGoBack())
      )
      .catch(() => {
        MySwal.fire({
          title: <p className="title">Error updating deal</p>,
          icon: "error",
        });
      })
      .finally(() => dispatch(stopSpinner()));
  };

  const FormDetails = () => {
    const handleCheckboxChange = (value, name: string) => {
      var arrayToUpdate = [] as (typeof value)[];
      let index = -1;

      var details = values.supplementalDetails;
      if (!!details) {
        arrayToUpdate = details[name.split(".")[1]];
        index = arrayToUpdate?.indexOf(value);
      }

      if (index === -1) {
        arrayToUpdate = [...arrayToUpdate, value];
      } else {
        arrayToUpdate = arrayToUpdate.filter((reason) => reason !== value);
      }

      handleChange({
        target: { value: arrayToUpdate, name: name },
      });
    };

    const handleNoWitness = (value) => {
      if (value === "NO") {
        setFieldValue("supplementalDetails.witnesses", []);
      }
    }

    const handleWitnessesChange = (witnesses) => {
      setFieldValue("supplementalDetails.witnesses", witnesses);
    };

    const saveDraft = () => {
      dispatch(startSpinner());
      var updatedLead = values as LeadViewModel;
      if (!!values.supplementalDetails?.employedTimeType) {
        updatedLead.supplementalDetails!.employedTimeType =
          EmployedTimeType[values.supplementalDetails.employedTimeType];
      }
      updatedLead.supplementalDetails!.isDraft = true;
      LeadsService.putApiLeadsUpdateSupplemental(+id!, updatedLead)
        .then(() =>
          MySwal.fire({
            title: (
              <p className="title">
                Supplemental form submitted successfully. You can finish it at
                any time.
              </p>
            ),
            icon: "success",
          }).then(() => onGoBack())
        )
        .catch(() => {
          MySwal.fire({
            title: <p className="title">Error updating supplemental form.</p>,
            icon: "error",
          });
        })
        .finally(() => dispatch(stopSpinner()));
    };

    const schema = yup.object().shape({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().email().required(),

      supplementalDetails: yup.object().shape({
        employedTimeType: yup.mixed().required(),
        uninterruptedBreaks: yup.mixed().required(),
        receivedLastPaycheck: yup.mixed().required(),
        lastPaycheckWhen: yup
          .mixed()
          .optional()
          .nullable()
          .when("receivedLastPaycheck", {
            is: RadioOptions.YES,
            then: (schema) => schema.required(),
          }),
        believeEmployerStillOwesMoney: yup.mixed().required(),
        haveCopyOfLastThreePayStubs: yup.mixed().required(),
        hasWitnesses: yup.mixed().required(),
        witnesses: yup.array(
          yup.object({
            name: yup.string().required(),
            canAttestTo: yup.string().required(),
          })
        ),
        alreadyFiledLawsuit: yup.mixed().required(),
      }),
    });

    const {
      values,
      errors,
      touched,
      isSubmitting,
      handleBlur,
      handleChange,
      handleSubmit,
      setFieldValue,
      setFieldTouched,
      resetForm,
    } = useFormik({
      validationSchema: schema,
      initialValues: details,
      onSubmit,
    });
    return (
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="row mt-3">
            <h5 className="title mb-0 mt-3">Client Info</h5>
            <div className="col-12 mt-3">Full legal name (for CSA)</div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="First name"
                variant="outlined"
                name="firstName"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="Last name"
                variant="outlined"
                name="lastName"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                required
                label="Email"
                variant="outlined"
                name="email"
                value={values.email}
                className={!!errors.email && !!touched.email ? "error" : ""}
                onBlur={handleBlur}
                onChange={(e) => setFieldValue("email", e.target.value.trim())}
              />
              {!!errors.email && !!touched.email && (
                <FormHelperText className="error-message">
                  {errors.email}
                </FormHelperText>
              )}
            </div>
            <div className="col-6 mt-3">
              <TextField
                label="Emergency Phone Number"
                variant="outlined"
                name="supplementalDetails.emergencyPhoneNumber"
                value={values.supplementalDetails?.emergencyPhoneNumber}
                onChange={handleChange}
              />
            </div>
          </div>
          <h5 className="title mb-0 mt-3">Details</h5>
          <div className="row">
            <div className="col-6 mt-3 dropdown-holder">
              <FormControl
                variant="outlined"
                sx={{ m: 1, minWidth: 120 }}
                className={
                  getIn(errors, "supplementalDetails.employedTimeType") &&
                    getIn(touched, "supplementalDetails.employedTimeType")
                    ? "error"
                    : ""
                }
              >
                <InputLabel id="employed-label">
                  Were you employed full or part time?*
                </InputLabel>
                <Select
                  labelId="employed-label"
                  label="Were you employed full or part time?"
                  id="fired"
                  name="supplementalDetails.employedTimeType"
                  value={values.supplementalDetails?.employedTimeType}
                  onChange={handleChange}
                >
                  {Object.keys(EmployedTimeType).map((key) => {
                    return (
                      <MenuItem key={key} value={key}>
                        {HelpersService.camelToString(EmployedTimeType[key])}
                      </MenuItem>
                    );
                  })}
                </Select>
                {getIn(errors, "supplementalDetails.employedTimeType") &&
                  getIn(touched, "supplementalDetails.employedTimeType") && (
                    <FormHelperText className="error-message">
                      Required
                    </FormHelperText>
                  )}
              </FormControl>
            </div>
            <div className="col-6 mt-3">
              <TextField
                label="How many hours per week?"
                variant="outlined"
                name="supplementalDetails.hoursWorkedPerWeek"
                value={values.supplementalDetails?.hoursWorkedPerWeek}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                label="Regular schedule"
                variant="outlined"
                name="supplementalDetails.workSchedule"
                placeholder="What days per week did you work? Start time, end time?"
                value={values.supplementalDetails?.workSchedule}
                onChange={handleChange}
              />
            </div>
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "supplementalDetails.uninterruptedBreaks") &&
                getIn(touched, "supplementalDetails.uninterruptedBreaks")
              }
              propertyName="supplementalDetails.uninterruptedBreaks"
              label="Did you get uninterrupted breaks?"
              class="col-6 mt-3"
              value={values.supplementalDetails?.uninterruptedBreaks}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className="col-6 mt-3">
              <TextField
                label="At what times did you take breaks?"
                variant="outlined"
                name="supplementalDetails.breaksTakenWhen"
                value={values.supplementalDetails?.breaksTakenWhen}
                onChange={handleChange}
              />
            </div>
            {values.supplementalDetails?.uninterruptedBreaks ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.NO
              ) && (
                <div className="col-12 mt-3">
                  <TextField
                    label="Explain"
                    variant="outlined"
                    name="supplementalDetails.noBreaksExplanation"
                    value={values.supplementalDetails?.noBreaksExplanation}
                    onChange={handleChange}
                  />
                </div>
              )}

            <div className="col-6 mt-3">
              <TextField
                label="How long were your breaks?"
                variant="outlined"
                name="supplementalDetails.breaksHowLong"
                value={values.supplementalDetails?.breaksHowLong}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                label="Did your employer have a written policy on when to take breaks?"
                variant="outlined"
                name="supplementalDetails.havePolicyWhenToTakeBreaks"
                value={values.supplementalDetails?.havePolicyWhenToTakeBreaks}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3 datepicker-holder">
              <FormControl>
                <DatePicker
                  disableFuture
                  label="What is the last date you worked?"
                  value={
                    !!values.supplementalDetails?.lastDayWorked
                      ? dayjs(values.supplementalDetails?.lastDayWorked)
                      : null
                  }
                  onChange={(date) =>
                    handleChange({
                      target: {
                        value: date,
                        name: "supplementalDetails.lastDayWorked",
                      },
                    })
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="row">
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "supplementalDetails.receivedLastPaycheck") &&
                getIn(touched, "supplementalDetails.receivedLastPaycheck")
              }
              propertyName="supplementalDetails.receivedLastPaycheck"
              label="Did you receive your last paycheck?"
              class={
                values.supplementalDetails?.receivedLastPaycheck ===
                  RadioYesOption
                  ? "col-6 mt-3"
                  : undefined
              }
              value={values.supplementalDetails?.receivedLastPaycheck}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.supplementalDetails?.receivedLastPaycheck ===
              RadioYesOption && (
                <>
                  <RadioControl
                    propertyName="supplementalDetails.lastPaycheckIncludesAllWages"
                    label="Did it include all of your accrued wages?"
                    class="col-6 mt-3"
                    value={
                      values.supplementalDetails?.lastPaycheckIncludesAllWages
                    }
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                  <div className="col-6 mt-3 datepicker-holder">
                    <FormControl
                      required
                      error={
                        getIn(errors, "supplementalDetails.lastPaycheckWhen") &&
                        getIn(touched, "supplementalDetails.lastPaycheckWhen")
                      }
                    >
                      <DatePicker
                        disableFuture
                        label="When did you receive your last paycheck?"
                        value={
                          !!values.supplementalDetails?.lastPaycheckWhen
                            ? dayjs(values.supplementalDetails?.lastPaycheckWhen)
                            : null
                        }
                        onChange={(date) =>
                          handleChange({
                            target: {
                              value: date,
                              name: "supplementalDetails.lastPaycheckWhen",
                            },
                          })
                        }
                        className={
                          getIn(errors, "supplementalDetails.lastPaycheckWhen") &&
                            getIn(touched, "supplementalDetails.lastPaycheckWhen")
                            ? "error"
                            : ""
                        }
                      />
                      {getIn(errors, "supplementalDetails.lastPaycheckWhen") &&
                        getIn(
                          touched,
                          "supplementalDetails.lastPaycheckWhen"
                        ) && <FormHelperText>Required</FormHelperText>}
                    </FormControl>
                  </div>
                  <RadioControl
                    propertyName="supplementalDetails.lastPaycheckIncludesVacations"
                    label="Did it include your accrued vacation?"
                    class="col-6 mt-3"
                    value={
                      values.supplementalDetails?.lastPaycheckIncludesVacations
                    }
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                  />
                </>
              )}
            <RadioControl
              isRequired
              requiredError={
                getIn(
                  errors,
                  "supplementalDetails.believeEmployerStillOwesMoney"
                ) &&
                getIn(
                  touched,
                  "supplementalDetails.believeEmployerStillOwesMoney"
                )
              }
              propertyName="supplementalDetails.believeEmployerStillOwesMoney"
              label="Do you believe the employer still owes you money for unpaid wages?"
              class={
                values.supplementalDetails?.believeEmployerStillOwesMoney ===
                  RadioYesOption
                  ? "col-6 mt-3"
                  : undefined
              }
              value={values.supplementalDetails?.believeEmployerStillOwesMoney}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.supplementalDetails?.believeEmployerStillOwesMoney ===
              RadioYesOption && (
                <>
                  <div className="col-6 mt-3">
                    <TextField
                      label="If so, how much?"
                      type="number"
                      variant="outlined"
                      name="supplementalDetails.employerOwesHowMuch"
                      value={values.supplementalDetails?.employerOwesHowMuch}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      label="What type of unpaid wages?"
                      variant="outlined"
                      name="supplementalDetails.employerOwesWagesType"
                      placeholder="Regular hours, overtime hours, unpaid vacation?"
                      value={values.supplementalDetails?.employerOwesWagesType}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-6 mt-3">
                    <TextField
                      label="How have you calculated this amount?"
                      variant="outlined"
                      name="supplementalDetails.employerOwesHowCalculatedAmount"
                      value={
                        values.supplementalDetails
                          ?.employerOwesHowCalculatedAmount
                      }
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            <RadioControl
              isRequired
              requiredError={
                getIn(
                  errors,
                  "supplementalDetails.haveCopyOfLastThreePayStubs"
                ) &&
                getIn(
                  touched,
                  "supplementalDetails.haveCopyOfLastThreePayStubs"
                )
              }
              propertyName="supplementalDetails.haveCopyOfLastThreePayStubs"
              label="Do you have a copy of your last three pay stubs?"
              class="col-6 mt-3"
              value={values.supplementalDetails?.haveCopyOfLastThreePayStubs}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.supplementalDetails?.haveCopyOfLastThreePayStubs ===
              RadioYesOption && (
                <div>
                  Can I have a copy to verify your employment information and to
                  see if it contains all of the information required under
                  California law? (Lab. Code § 226(a))
                  <br />
                  Please have clients obtain the last 3 pay stubs and send them
                  via email.
                </div>
              )}
            {values.supplementalDetails?.haveCopyOfLastThreePayStubs ===
              HelpersService.getEnumKeyByEnumValue(
                RadioOptions,
                RadioOptions.NO
              ) && (
                <>
                  <div>
                    Please ask client if there is any way to obtain the last 3 pay
                    stubs and send them via email.
                    <br />
                    If they can't get the last 3, they should provide us with at
                    least one, preferrably the last one.
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="Explanation for no and/​or efforts to get it"
                      variant="outlined"
                      name="supplementalDetails.whyNoOrEffortsToGetStubs"
                      placeholder={
                        'E.g. "Will look for it and get back to us", "Doesn\'t have access", etc.'
                      }
                      value={values.supplementalDetails?.whyNoOrEffortsToGetStubs}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
          </div>
          <div className="row mt-3">
            <div className="col-12 mt-3">
              <FormControl component="fieldset" style={{ display: "flex" }}>
                <FormLabel component="legend">
                  Did any of the following occur throughout employment?
                </FormLabel>
                <FormGroup className="flex-row">
                  {Object.keys(EmployeePunishment).map((opt) => (
                    <div className="col-6 col-md-2" key={opt}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.supplementalDetails?.whatPunishmentsOcurred?.includes(
                              EmployeePunishment[opt]
                            )}
                            onChange={(event) => {
                              handleCheckboxChange(
                                event.target.value as EmployeePunishment,
                                "supplementalDetails.whatPunishmentsOcurred"
                              );
                            }}
                            value={EmployeePunishment[opt]}
                          />
                        }
                        label={HelpersService.camelToString(
                          EmployeePunishment[opt]
                        )}
                      />
                    </div>
                  ))}
                </FormGroup>
              </FormControl>
            </div>
            {!!values.supplementalDetails?.whatPunishmentsOcurred &&
              values.supplementalDetails?.whatPunishmentsOcurred.length > 0 && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      label="What was the reason given by the employer?"
                      variant="outlined"
                      name="supplementalDetails.punishmentReasonGiven"
                      value={values.supplementalDetails?.punishmentReasonGiven}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="What was the reason you think it was done improperly?"
                      variant="outlined"
                      name="supplementalDetails.punishmentReasonWhyImproper"
                      value={
                        values.supplementalDetails?.punishmentReasonWhyImproper
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="Do you have any evidence to support this?"
                      variant="outlined"
                      name="supplementalDetails.punishmentSupportEvidence"
                      value={
                        values.supplementalDetails?.punishmentSupportEvidence
                      }
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "supplementalDetails.hasWitnesses") &&
                getIn(touched, "supplementalDetails.hasWitnesses")
              }
              propertyName="supplementalDetails.hasWitnesses"
              label="Are there any witnesses?"
              class="col-6 mt-3"
              value={values.supplementalDetails?.hasWitnesses}
              handleBlur={handleBlur}
              handleChange={(e) => {
                handleChange(e);
                handleNoWitness(e.target.value);
              }}
            />
            {values.supplementalDetails?.hasWitnesses === RadioYesOption && (
              <WitnessFormTable
                initValues={values.supplementalDetails?.witnesses ?? null}
                onChange={handleWitnessesChange}
              ></WitnessFormTable>
            )}
          </div>

          <div className="row mt-3">
            <h5 className="title mb-0 mt-3">Damages</h5>
            <div className="mt-3">If Termination</div>
            <div className="col-6 mt-3">
              <TextField
                label="How long has it been since termination?"
                variant="outlined"
                name="supplementalDetails.howLongSinceTermination"
                value={values.supplementalDetails?.howLongSinceTermination}
                onChange={handleChange}
              />
            </div>

            <div className="mt-3">If Unpaid Leaves</div>
            <div className="col-6 mt-3">
              <TextField
                label="Dates of Leaves"
                variant="outlined"
                name="supplementalDetails.unpaidLeavesDates"
                value={values.supplementalDetails?.unpaidLeavesDates}
                onChange={handleChange}
              />
            </div>
            <div className="col-6 mt-3">
              <TextField
                label="Length of leaves?"
                variant="outlined"
                name="supplementalDetails.unpaidLeavesLength"
                value={values.supplementalDetails?.unpaidLeavesLength}
                onChange={handleChange}
              />
            </div>

            <div className="mt-3">If demotion/salary wage reduction</div>
            <RadioControl
              propertyName="supplementalDetails.documentedEmotionalDistress"
              label="Any documented emotional distress?"
              value={values.supplementalDetails?.documentedEmotionalDistress}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.supplementalDetails?.documentedEmotionalDistress ===
              RadioYesOption && (
                <div className="col-12 mt-3">
                  <TextField
                    label="Have you seen therapist, been prescribed medications?"
                    variant="outlined"
                    name="supplementalDetails.emotionalDistressDetails"
                    multiline
                    value={values.supplementalDetails?.emotionalDistressDetails}
                    onChange={handleChange}
                  />
                </div>
              )}
            <RadioControl
              propertyName="supplementalDetails.filedBankruptcy"
              label="Have you ever filed BANKRUPTCY?"
              value={values.supplementalDetails?.filedBankruptcy}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.supplementalDetails?.filedBankruptcy === RadioYesOption && (
              <>
                <div className="col-6 mt-3">
                  <TextField
                    label="When?"
                    variant="outlined"
                    name="supplementalDetails.filedBankruptcyWhen"
                    value={
                      values.supplementalDetails?.filedBankruptcyWhen
                    }
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6 mt-3">
                  <TextField
                    label="Discharged?"
                    variant="outlined"
                    name="supplementalDetails.filedBankruptcyDischarged"
                    value={
                      values.supplementalDetails?.filedBankruptcyDischarged
                    }
                    onChange={handleChange}
                  />
                </div>
              </>
            )}
          </div>

          <div className="row mt-3">
            <h5 className="title mb-0 mt-3">EMPLOYMENT TERMS</h5>
            <RadioControl
              propertyName="supplementalDetails.providedEmployeeHandbook"
              label="Was employee provided an Employee Handbook?"
              value={values.supplementalDetails?.providedEmployeeHandbook}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              propertyName="supplementalDetails.haveWrittenContract"
              label="Do you have a written contract for employment with the employer?"
              value={values.supplementalDetails?.haveWrittenContract}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </div>

          <div className="row mt-3">
            <h5 className="title mb-0 mt-3">JOB PERFORMANCE</h5>
            <div className="col-12 mt-3">
              <TextField
                label="Did you receive written or spoken evaluations of your work? In general, what was said?"
                variant="outlined"
                multiline
                name="supplementalDetails.receivedEvaluationsOfWork"
                value={values.supplementalDetails?.receivedEvaluationsOfWork}
                onChange={handleChange}
              />
            </div>
            <RadioControl
              propertyName="supplementalDetails.notifiedOfNegativeFeedbackInWriting"
              label="Were you notified of the negative feedback in writing?"
              value={
                values.supplementalDetails?.notifiedOfNegativeFeedbackInWriting
              }
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <RadioControl
              propertyName="supplementalDetails.respondedToFeedbackInWriting"
              label="Did you respond to the written notice(s) in writing?"
              value={values.supplementalDetails?.respondedToFeedbackInWriting}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
          </div>

          <div className="row mt-3">
            <RadioControl
              isRequired
              requiredError={
                getIn(errors, "supplementalDetails.alreadyFiledLawsuit") &&
                getIn(touched, "supplementalDetails.alreadyFiledLawsuit")
              }
              propertyName="supplementalDetails.alreadyFiledLawsuit"
              label="Have you already filed a lawsuit?"
              value={values.supplementalDetails?.alreadyFiledLawsuit}
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            {values.supplementalDetails?.alreadyFiledLawsuit ===
              RadioYesOption && (
                <>
                  <div className="col-12 mt-3">
                    <TextField
                      label="When was it filed?"
                      variant="outlined"
                      name="supplementalDetails.filedLawsuitWhen"
                      value={values.supplementalDetails?.filedLawsuitWhen}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="What is the name and phone number of the attorney representing you?"
                      variant="outlined"
                      name="supplementalDetails.nameAndPhoneOfRepresentingAttorney"
                      value={
                        values.supplementalDetails
                          ?.nameAndPhoneOfRepresentingAttorney
                      }
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <TextField
                      label="Why are you looking for a new attorney?"
                      variant="outlined"
                      name="supplementalDetails.whyLookingForNewAttorney"
                      value={values.supplementalDetails?.whyLookingForNewAttorney}
                      onChange={handleChange}
                    />
                  </div>
                </>
              )}
          </div>

          <div className="row">
            <div className="col-12 mt-3">
              <TextField
                label="Is there anything else you think we should know?"
                variant="outlined"
                name="supplementalDetails.anythingElseWeShouldKnow"
                multiline
                value={values.supplementalDetails?.anythingElseWeShouldKnow}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            <div className="col-12 mt-3">
              <TextField
                label="Any description/notes not given in initial intake"
                variant="outlined"
                name="supplementalDetails.supplementalNotes"
                multiline
                value={values.supplementalDetails?.supplementalNotes}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
          </div>
        </fieldset>
        <div className="row mb-5 mt-5">
          <div className="width-content">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={() => console.log(errors)}
            >
              Submit
            </Button>
            {!!errors && Object.keys(errors).length > 0 && (
              <div className="error-message">Errors in form</div>
            )}
          </div>
          {details.status !== AdminLeadStatus.DONE && (
            <div className="width-content">
              <Button
                variant="contained"
                color="secondary"
                onClick={saveDraft}
              >
                Save draft
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="row main-holder">
      <Button
        variant="contained"
        color="gray"
        className="col-3 mb-3"
        startIcon={<KeyboardArrowLeftIcon />}
        onClick={onGoBack}
      >
        Go back
      </Button>
      <h4 className="title">Supplemental intake </h4>
      {!!details && <FormDetails></FormDetails>}
    </div>
  );
}
