import { useEffect, useState } from "react";
import { ClioService, ClioTasksInfo, TaskTypes } from "../../api";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
export default function ClioTaskDataUpdate() {
    const [allTasks, setAllTasks] = useState<ClioTasksInfo[]>([]);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [selectedTaskName, setSelectedTaskName] = useState<string | null>(
        null
    );
    const [selectedTaskDays, setSelectedTaskDays] = useState<number | null>(
        null
    );
    const [selectedTaskDescription, setSelectedTaskDescription] = useState<string | null>(
        null
    );
    const [resetDisabled, setResetDisabled] = useState(false);

    useEffect(() => {
        ClioService.getApiClioTaskData().then((tasks) => {
            setAllTasks(tasks);
        });
    }, []);

    const handleUpdate = () => {
        if (!selectedId || !selectedTaskName || !selectedTaskDays || !selectedTaskDescription) {
            return;
        }
        ClioService.postApiClioTaskDataUpdate(selectedId, { name: selectedTaskName, dueDays: selectedTaskDays, description: selectedTaskDescription }).then(() => MySwal.fire({
            icon: "success",
            title: (
                <p className="title">
                    Task updated.
                </p>
            ),
        })).catch(() => MySwal.fire({
            icon: "error",
            title: (
                <p className="title">
                    Error updating task.
                </p>
            ),
        }));
    }

    const resetAutomation = () => {
        setResetDisabled(true);
        ClioService.postApiClioResetWebhooks().then(() => MySwal.fire({
            icon: "success",
            title: (
                <p className="title">
                    Automation reset. Please wait a minute then change test matter state and complete any task. These first actions might not be automated.
                </p>
            ),
        })).catch(() => MySwal.fire({
            icon: "error",
            title: (
                <p className="title">
                    Error reseting automation.
                </p>
            ),
        })).finally(() => setResetDisabled(false));
    }

    return (<>
        <div className="row flex-row-reverse">
            <Button
                className="my-2 col-2"
                variant="contained"
                disabled={resetDisabled}
                onClick={resetAutomation}
            >
                Reset automation
            </Button>
        </div>
        <FormControl>
            <InputLabel id="type-label">Task</InputLabel>
            <Select
                labelId="type-label"
                name="task"
                className="min-width-200"
                label="Task"
                value={selectedId}
                onChange={(e) => {
                    var id = e.target.value !== null ? +e.target.value : null;
                    setSelectedId(id);
                    var selectedTask = allTasks.find(t => t.id === id) ?? null;
                    setSelectedTaskName(selectedTask?.name ?? null);
                    setSelectedTaskDays(selectedTask?.dueDays ?? null);
                    setSelectedTaskDescription(selectedTask?.description ?? null);
                }}
            >
                {allTasks.map((task) => {
                    return (
                        <MenuItem key={task.id} value={task.id}>
                            {task.taskType}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
        {selectedId &&
            <div className="row main-holder mt-4">
                <div className="col-6">
                    <TextField
                        required
                        label="Name"
                        variant="outlined"
                        value={selectedTaskName}
                        onChange={(e) =>
                            setSelectedTaskName(e.target.value.trim())
                        }
                    />
                </div>
                <div className="col-6">
                    <TextField
                        required
                        label="Due days"
                        variant="outlined"
                        value={selectedTaskDays}
                        type="number"
                        onChange={(e) =>
                            setSelectedTaskDays(+e.target.value)
                        }
                    />
                    <FormHelperText>
                        !Working days!
                    </FormHelperText>
                </div>
                <div className="col-12">
                    <TextField
                        required
                        label="Description"
                        variant="outlined"
                        multiline
                        value={selectedTaskDescription}
                        onChange={(e) =>
                            setSelectedTaskDescription(e.target.value)
                        }
                    />
                </div>
                <Button
                    className="my-2 col-2"
                    variant="contained"
                    onClick={handleUpdate}
                >
                    Update
                </Button>
            </div>
        }
    </>);
}